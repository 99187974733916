import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom/cjs/react-router-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "./styles/globals.scss";
import ErrorBoundary from "./components/error-boundary";
import AppContextProvider from "./context/app-context";
import AuthContextProvider from "./context/auth-context";
import PageContextProvider from "./context/page-context";
import App from "./components/app/app";

Sentry.init({
  dsn: process?.env?.REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ErrorBoundary>
        <AppContextProvider>
          <AuthContextProvider>
            <PageContextProvider>
              <App />
            </PageContextProvider>
          </AuthContextProvider>
        </AppContextProvider>
      </ErrorBoundary>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
