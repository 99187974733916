import { gql } from "@apollo/client";
import client from "../client";

const formatDiagnostics = (data = null) => {
  if (data === null) return null;
  const { slug, title, id, content } = data;
  const { sections = [], yes, no, error_SectionNotAllowedYet } = (content?.[0] ?? {});

  return {
    id: parseInt(id),
    slug,
    content: {
      title,
      sections: sections.map(({ title, description, id, related, dateUpdated }) => ({
        title,
        description,
        id: parseInt(id),
        related: related.map(({id}) => parseInt(id)),
        dateUpdated,
      })),
      buttons: {
        yes: yes || "Yes",
        no: no || "No",
      },
      errors: {
        sectionNotAllowedYet:
            error_SectionNotAllowedYet || "Section not allowed yet",
      },
    },
  };
}

export default async function fetchDiagnosticsPage() {
  const { data } = await client.query({
    query: gql`
      query Page {
        data: entries(section: "pages", slug: "diagnostics") {
          id
          title
          slug
          ... on pages_page_Entry {
            content: pageTypes(limit: 1) {
              ... on pageTypes_qA_BlockType {
                yes: yesButtonText
                no: noButtonText
                error_SectionNotAllowedYet
                sections: diagnostics {
                  id
                  title
                  dateUpdated
                  ... on diagnostics_diagnostics_Entry {
                    description
                    related: relatedLearningPathways {
                      id
                      slug
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  });

  return formatDiagnostics(data?.data?.[0] ?? null);
}
