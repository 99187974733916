import React from "react";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom";
import { AuthContext } from "../../context/auth-context";

export default function ProtectedRoute({ exact, path, component }) {
  const { user } = React.useContext(AuthContext);

  return (
    <Route exact={exact} path={path}>
      {Object.keys(user || {}).length < 1 ? (
        <Redirect to="/login" />
      ) : (
        <Route component={component} />
      )}
    </Route>
  );
}
