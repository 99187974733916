import React from "react";
import moment from "moment";
import content from "../../config/content";
import { AuthContext } from "../../context/auth-context";
import Header from "../header/header";
import Button from "../button/button";
import fetchDiagnosticsPage from "../../services/api/pages/fetch-diagnostics-page";

export default function PageHeader() {
  const { user, logout } = React.useContext(AuthContext);
  const [workshopsCount, setWorkshopsCount] = React.useState(0);
  const [newDiagnosticsCount, setNewDiagnosticsCount] = React.useState(0);

  React.useEffect(() => {
    const workshops = (user?.workshops ?? []).filter(({datetime}) => moment(datetime).isSameOrAfter(moment()));
    setWorkshopsCount(workshops.length);
    fetchDiagnosticsPage().then(({content}) => {
      const datetimes = [];
      for (const section of (content?.sections ?? [])) {
        datetimes.push(section.dateUpdated);
      }
      const lastSurvey = moment(user?.diagnostics?.last_survey ?? moment().subtract(1, 'year'));
      const newDiagnostics = datetimes.filter(datetime => moment(datetime).isAfter(lastSurvey));
      setNewDiagnosticsCount(newDiagnostics.length);
    });

  }, [user, setWorkshopsCount, setNewDiagnosticsCount]);

  return (
    <Header>
      {(user?.diagnostics ?? null) !== null && (
        <>
          <Header.Link to="/account">
            {content.get("header-account")}
            <img src="/assets/icon-account.svg" alt="" />
          </Header.Link>
          <Header.Link to="/diagnostics">
            {content.get("header-diagnostics")}
            <img src="/assets/icon-diagnostic.svg" alt="" />
            {newDiagnosticsCount > 0 && <span className="notification-count new-diagnostics-count">{newDiagnosticsCount}</span>}
          </Header.Link>
          <Header.Link to="/calendar">
            {content.get("header-calendar")}
            <img src="/assets/icon-calendar.svg" alt="" />
            <span className="notification-count workshops-count">{workshopsCount}</span>
          </Header.Link>
          <Button to="/workshops" variant="yellow">
            {content.get("header-workshops")}
          </Button>
          <Button onClick={logout} variant="dark-blue" style={{color: '#fff'}}>
            Logout
          </Button>
        </>
      )}
    </Header>
  );
}
