import React from "react";
import browserDetect from "browser-detect";

export const AppContext = React.createContext({});

export default function AppContextProvider({ children }) {
  const browser = React.useMemo(() => {
    const result = browserDetect();
    const version = parseInt(result.versionNumber);
    return {
      id: `${result.name}-${version}`,
      name: result.name,
      mobile: result.mobile,
      version,
    };
  }, []);
  const [progress, setProgress] = React.useState(25);

  return (
    <AppContext.Provider value={{ browser, progress, setProgress }}>
      {children}
    </AppContext.Provider>
  );
}
