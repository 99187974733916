import React from "react";
import "./page.scss";
import PageHeader from "./header";
import Content from "./content";

export default function Page({ children, name }) {
  return (
    <div className={`page page--${name}`}>
      {children}
    </div>
  );
}

Page.Header = PageHeader;
Page.Content = Content;
