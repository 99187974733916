import React from "react";
import * as Sentry from "@sentry/react";

export default class ErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error);
    // todo: gracefully handle unknown errors here
  }

  render() {
    return (
      <>
        {this.props.children}
      </>
    )
  }
}
