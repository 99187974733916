import React from "react";
import Request from "../services/request";

export const AuthContext = React.createContext({});

export default function AuthContextProvider({ children }) {
  const [loaded, setLoaded] = React.useState(false);
  const [user, setUser] = React.useState({});

  const updateUser = React.useCallback(
    async (newUser, login = false) => {
      if (!newUser) {
        return;
      }
      if (login) {
        localStorage.setItem("auth.user", JSON.stringify(newUser));
        setUser(newUser);
      } else {
        const user = JSON.parse(localStorage.getItem("auth.user")) ?? {};
        newUser = { ...user, ...newUser };
        await Request.patch("/save-user", newUser);
        localStorage.setItem("auth.user", JSON.stringify(newUser));
        setUser(newUser);
      }
    },
    [setUser]
  );

  const login = React.useCallback(
    async ({ jwt, user }) => {
      updateUser({ ...user, jwt }, true);
    },
    [updateUser]
  );

  const logout = React.useCallback(async () => {
    localStorage.removeItem("auth.user");
    setUser(null);
  }, [setUser]);

  const loadUser = React.useCallback(async () => {
    if (loaded) return;
    const res = await Request.get("/user");
    const backupUser = JSON.parse(localStorage.getItem("auth.user")) ?? null;
    setUser(res.success ? res?.body?.user : backupUser);
    setLoaded(true);
  }, [loaded, setLoaded, setUser]);

  React.useEffect(() => {
    if (loaded) return;
    loadUser();
  }, [loaded, loadUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser: updateUser,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
