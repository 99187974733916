import React from "react";
import { Switch } from "react-router-dom/cjs/react-router-dom";
import "./app.scss";
import { AppContext } from "../../context/app-context";
import { AuthContext } from "../../context/auth-context";
import { PageContext } from "../../context/page-context";
import Page from "../page/page";
import ProtectedRoute from "../protected-route/protected-route";
import AuthRoute from "../auth-route/auth-route";

const Login = React.lazy(() => import("../../pages/login/login"));
const Register = React.lazy(() => import("../../pages/register/register"));
const Activate = React.lazy(() => import("../../pages/activate/activate"));
const ResetPassword = React.lazy(() => import("../../pages/reset-password/reset-password"));
const ForgotPassword = React.lazy(() =>
  import("../../pages/forgot-password/forgot-password")
);
const AvatarBuilder = React.lazy(() =>
  import("../../pages/avatar-builder/avatar-builder")
);
const Diagnostics = React.lazy(() =>
  import("../../pages/diagnostics/diagnostics")
);
const Welcome = React.lazy(() => import("../../pages/welcome/welcome"));
const Home = React.lazy(() => import("../../pages/home/home"));
const Behaviours = React.lazy(() =>
  import("../../pages/behaviours/behaviours")
);
const Skills = React.lazy(() => import("../../pages/skills/skills"));
const Account = React.lazy(() => import("../../pages/account/account"));
const Workshops = React.lazy(() => import("../../pages/workshops/workshops"));
const Calendar = React.lazy(() => import("../../pages/calendar/calendar"));
const Summary = React.lazy(() => import("../../pages/summary/summary"));
const ExploreSkills = React.lazy(() =>
  import("../../pages/explore-skills/explore-skills")
);
const ExploreBehaviours = React.lazy(() =>
  import("../../pages/explore-behaviours/explore-behaviours")
);

// @todo add 404 page
export default function App() {
  const { browser } = React.useContext(AppContext);
  const { user } = React.useContext(AuthContext);
  const { name } = React.useContext(PageContext);
  const showHeader = user !== null;

  return (
    <div
      className={`app app-browser--${browser?.id ?? "unknown"} app-header--${
        showHeader ? "show" : "hide"
      }`}
    >
      <Page name={name}>
        {showHeader && <Page.Header />}
        <React.Suspense fallback="Loading...">
          <Switch>
            <AuthRoute exact path="/login" component={Login} />
            <AuthRoute exact path="/register" component={Register} />
            <AuthRoute exact path="/activate/:id/:code" component={Activate} />
            <AuthRoute
              exact
              path="/reset-password/:id/:code"
              component={ResetPassword}
            />
            <AuthRoute
              exact
              path="/forgot-password"
              component={ForgotPassword}
            />
            <ProtectedRoute
              exact
              path="/avatar-builder"
              component={AvatarBuilder}
            />
            <ProtectedRoute exact path="/diagnostics" component={Diagnostics} />
            <ProtectedRoute exact path="/welcome" component={Welcome} />
            <ProtectedRoute exact path="/" component={Home} />
            <ProtectedRoute exact path="/behaviours" component={Behaviours} />
            <ProtectedRoute exact path="/skills" component={Skills} />
            <ProtectedRoute exact path="/account" component={Account} />
            <ProtectedRoute exact path="/workshops" component={Workshops} />
            <ProtectedRoute exact path="/calendar" component={Calendar} />
            <ProtectedRoute
              exact
              path="/explore/skills"
              component={ExploreSkills}
            />
            <ProtectedRoute
              exact
              path="/explore/behaviours"
              component={ExploreBehaviours}
            />
            <ProtectedRoute exact path="/summary" component={Summary} />
          </Switch>
        </React.Suspense>
      </Page>
    </div>
  );
}
