import React from "react";

export const PageContext = React.createContext({});

export default function PageContextProvider({ children }) {
  const [name, setName] = React.useState("");
  const [pageData, setPageData] = React.useState("");

  return (
    <PageContext.Provider value={{ name, setName, pageData, setPageData }}>
      {children}
    </PageContext.Provider>
  );
}
