export const COL_ONE_THIRD = 100 / 3;

export const MASK_COLOR_BLUE = "rgba(31, 97, 160, 0.75)";

export const COLOR_YELLOW = "#fbc33a";
export const COLOR_TEAL = "#163559";
export const COLOR_BLUE = "#1f61a0";
export const COLOR_DARK_BLUE = "#13233a";
export const COLOR_WHITE = "#ffffff";

export const TYPE_LABELS = {
  learn: `Learn +`,
  link: `Link`,
  pdf: `PDF`,
};

export const AUTH_ROUTES = [
  'register',
  'activate',
  'login',
  'forgot',
  'reset',
];

export const LEARNING_LEVELS = [
  'All',
  'Basic',
  'Intermediate',
  'Advanced',
]
