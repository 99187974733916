import React from "react";
import { Link, useLocation } from "react-router-dom/cjs/react-router-dom";
import "./header.scss";
import Container from "../container/container";

export default function Header({ children }) {
  const location = useLocation();
  const hideSlugs = ['/welcome', '/skills', '/behaviours'].includes(location.pathname);
  return (
    <div className="header">
      <Container>
        <Link className="header-logo" to="/">
          <img src="/assets/logo.svg" alt="Shape the Future Me" />
        </Link>
        {!hideSlugs && <div className="header-content">{children}</div>}
      </Container>
    </div>
  );
}

Header.Link = ({ children, to }) => {
  return (
    <Link className="header-link" to={to}>
      {children}
    </Link>
  );
};
