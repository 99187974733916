const content = new Map();

const matrix = {
  "header-account": `Account`,
  "header-diagnostics": `Diagnostic Q&A`,
  "header-calendar": `Workshop(s) Calendar`,
  "header-workshops": `Workshops`,
  "welcome-content": `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>`,
  "welcome-intro-background": `/uploads/welcome-intro-background.jpg`,
  "welcome-avatar-background": `/uploads/welcome-avatar-background.jpg`,
  "welcome-summary-title": `Explore Chosen Learning Opportunities`,
  "welcome-summary-button": `View Summary`,
  "welcome-behaviours-title": `Manage Ways of Working & Behaviors`,
  "welcome-behaviours-button": `Explore All`,
  "welcome-skills-title": `Mange Skills`,
  "welcome-skills-button": `Explore All`,
  "account-hero-background": `/uploads/account-hero-background.jpg`,
  "account-hero-title": `Manage Your Account`,
  "account-hero-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  "account-title": `Would you like to update your Password?`,
  "account-description": `Please enter your email below to update your password:`,
  "account-form-submit": `Update`,
  "account-modal-title": `Lorem ipsum dolor sit amet, consectetur adipiscing elit`,
  "account-modal-content": `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>`,
  "account-form-password-placeholder": `***********`,
  "workshops-hero-background": `/uploads/workshops-hero-background.jpg`,
  "workshops-hero-description": `<p>We will promote internal workshops which are linked to our ways of working & behaviours and skills. Please continue to check this page so you can benefit from your colleagues’ experience and expertise.</p>`,
  "workshops-title": `Workshops`,
  "workshops-description": `<p>In addition to the below, you can also look for a specific workshop or run a search according to the ways of working & behaviours and skills you’re interested in exploring.</p>`,
  "calendar-hero-background": `/uploads/calendar-hero-background.jpg`,
  "calendar-hero-title": `My Calendar`,
  "calendar-hero-description": `<p>Your time is precious, so let us help you to use it wisely by exploring workshops on your chosen areas of interest.</p>`,
  "calendar-title": `Search / Filter:`,
  "explore-like-what-you-see": `<p>Like what you see? If yes, select your learning level and add it to your learning list.</p>`,
  "explore-behaviours-title": `Exploring your Learning Opportunities`,
  "explore-behaviours-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "explore-behaviours-background": `/uploads/explore-behaviours-background.jpg`,
  "explore-behaviours-wow-title": `Ways of Working & Behaviours`,
  "explore-behaviours-wow-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "explore-behaviours-wow-button": `Add Ways of Working & Behaviors`,
  "explore-behaviours-skills-title": `Skills`,
  "explore-behaviours-skills-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "explore-behaviours-skills-button": `Add Skills`,
  "summary-title": `Exploring your Learning Opportunities`,
  "summary-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "summary-wow-title": `Your Ways of Working & Behaviours`,
  "summary-wow-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "summary-skills-title": `Your Skills`,
  "summary-skills-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "behaviours-title": `Ways of Working & Behaviors`,
  "behaviours-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "behaviours-button": `Explore All Ways of Working & Behaviors`,
  "skills-title": `Skills`,
  "skills-description": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
  "skills-button": `Explore All Skills`,
};

// Update content map
for (const key in matrix) {
  if (!matrix.hasOwnProperty(key)) continue;
  content.set(key, matrix[key]);
}

export default content;
