import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./button.scss";

export default function Button({
  children,
  maxWidth,
  className = "",
  variant = "",
  style = {},
  ...props
}) {
  const Component = React.useMemo(() => {
    if (props.to) {
      return Link;
    }
    if (props.href) {
      return "a";
    }
    return "button";
  }, [props]);

  return (
    <Component
      {...props}
      className={`button button--${variant} ${className}`}
      style={{ ...style, maxWidth }}
    >
      <span>{children}</span>
    </Component>
  );
}
