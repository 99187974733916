import React from "react";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom";
import { AuthContext } from "../../context/auth-context";

export default function AuthRoute({ exact, path, component }) {
  const { user } = React.useContext(AuthContext);

  return (
    <Route exact={exact} path={path}>
      {Object.keys(user?? {}).length > 0 ? <Redirect to="/" /> : <Route component={component} />}
    </Route>
  );
}
