import request from "axios";
import get from "lodash/get";
import {AUTH_ROUTES} from "../config/consts";

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  'Accept': 'application/json',
};

const pendingRequests = [];

const cancelRequest = token => {
  for (let i = pendingRequests.length - 1; i >= 0; i--) {
    if (pendingRequests[i].token === token) {
      pendingRequests.splice(i, 1);
      break;
    }
  }
};

const sendRequest = ({method, path, data = {}, type = 'json', progressCallback = null, headers = {}}) => new Promise(async resolve => {
  headers = Object.assign(DEFAULT_HEADERS, headers);
  path = path.replace(/^\/+/, '').replace(/\/+$/, '');

  // We're using JWT here to authenticate, so if the token is not in
  // localStorage, then the user is definitely not authenticated.
  // NOTE: We ignore any routes that are public (defined in app config).
  const user = JSON.parse(localStorage.getItem('auth.user')) || {};
  let endpoint = (process?.env?.REACT_APP_AUTH_API_ENDPOINT || window.location.origin).replace(/\/+$/, '');
  endpoint = `${endpoint}/${path}`;

  if (!AUTH_ROUTES.includes(path) && !user.hasOwnProperty('jwt')) {
    return resolve({success: false, errors: ['Unauthorised'], message: null, code: 401});
  }

  // Set the JWT in the Bearer header for all requests.
  headers['Authorization'] = `Bearer ${user.jwt || ''}`;

  // Set CORS header
  // headers['Origin'] = process?.env?.REACT_APP_URL || window.location.origin;

  const CancelToken = request.CancelToken;
  const source = CancelToken.source();

  pendingRequests.push(source);

  try {
    const args = {cancelToken: source.token, headers};

    if (method === 'download') {
      method = 'get';
      args.responseType = 'blob';
    }

    const res = await request[method](endpoint, method === 'get' ? args : data, method === 'get' ? undefined : args);

    // Remove this request from the pending requests array
    cancelRequest(source.token);

    if (res === undefined) {
      // Server is disconnected
      return resolve({success: false, errors: ['Server disconnected'], message: null, code: 503});
    }

    if (res?.status < 300) {
      return resolve({
        success: true,
        body: res?.data,
        errors: [],
        message: get(res, 'data.message', null),
        code: res?.status,
      });
    }

    return resolve({
      success: false,
      body: res?.data ?? null,
      errors: get(res, 'errors', ['Server error']),
      message: get(res, 'data.message', null),
      code: res?.status ?? 500,
    });
  } catch (err) {
    // Remove this request from the pending requests array
    cancelRequest(source?.token);

    let errors = ['Unknown error'];

    if (err?.response?.status === 404) {
      errors = ['Page not found'];
    }

    if (err?.response?.status === 500) {
      errors = ['Internal server error'];
    }

    if (err?.response?.data && err?.response?.data?.errors) {
      errors = err?.response?.data?.errors ?? [];
    }

    resolve({
      errors,
      success: false,
      message: null,
      code: err?.response?.status,
    });
  }
});

export default class Request {
  static get(path, data = {}, headers = {}) {
    return sendRequest({method: 'get', path, data, headers});
  }

  static post(path, data = {}, headers = {}) {
    return sendRequest({method: 'post', path, data, headers});
  }

  static patch(path, data = {}, headers = {}) {
    return sendRequest({method: 'patch', path, data, headers});
  }

  static delete(path, data = {}, headers = {}) {
    return sendRequest({method: 'delete', path, data, headers});
  }

  static download(path, data = {}, headers = {}) {
    return sendRequest({method: 'download', path, data, headers});
  }

  static abortAllRequests() {
    // Stop all pending requests and remove them from the array
    for (let i = pendingRequests.length - 1; i >= 0; i--) {
      pendingRequests[i].cancel();
      pendingRequests.splice(i, 1);
    }
  }
}
